import React, { useEffect, useState, useRef } from 'react';
import { Loader, NavBarStyle1, SideBarStyle1 } from '../Components';
import { Collapse } from "reactstrap";
import { Switch, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
class NavBar extends React.Component {



    constructor(props) {
        super(props);

        this.state = {
            title: '',
            items: [
                {
                    text: '',
                    active: true
                }
            ],
            collapsed: false,
            width: props.width
        }
    }

    componentDidMount() {
        this.state.title = this.props.title;
        this.setState({
            width: window.innerWidth
        });

    }

    componentWillUnmount() {
    }
    
    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    Logout = () => {


    }
    render() {

        if (this.props != undefined && this.props.title != undefined) this.state.title = this.props.title;
        if (this.props != undefined && this.props.breadcrumb != undefined) this.state.items = this.props.breadcrumb;

        return (
            <>
            </>
        )
    }
}


export default NavBar;

