import React, { useEffect,useRef } from 'react'
import Swal from 'sweetalert2'
import {Row,Col} from "reactstrap";
import {LongdoMap,Payment} from '../Components';


const MapShow =()=> {
    const _PaymentRef = useRef();

    let _MAP;
    let _Longdo;
    useEffect(() => {

        (async () => {

        })();

    }, []);

    const initMap = (map,longdo)=>{
        _MAP = map;
        _Longdo = longdo;
        _StartMap();

    }
    const _StartMap = ()=>{
        _MAP.location({lat:18.825091580012064, lon:99.035821044114},true);
        _MAP.Layers.setBase(_Longdo.Layers.NORMAL);
        _MAP.Overlays.add(new _Longdo.Marker({lat:18.825091580012064, lon:99.035821044114}));
    }
    const TestMap =()=>{
        _MAP.Overlays.clear();
        _StartMap();
        const _Marker = new _Longdo.Marker({lat:18.796424002342818, lon:99.01611996771577});

        //_MAP.Overlays.add(new _Longdo.Marker({lat:18.796424002342818, lon:99.01611996771577}));

        _MAP.Route.add(_Marker);
        _MAP.Route.enableContextMenu();
        _MAP.Route.auto(true);

    }

    const _Payment = ()=>{
        _PaymentRef.current?.Show('');
        //_PaymentRef.current?.Show('');
    }
    return (
        <>
        <div className='row'>
            <div className='col-12' style={{'height':'500px'}}>
                <LongdoMap id="longdo-map" mapKey="9c48d2ab25e640963d56072e667fa31e" callback={initMap} />
            </div>
        </div>
        <div className='row mt-3'>
            <div className='col'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th className='text-center'>รายละเอียด</th>
                            <th className='text-center'>ปลายทาง</th>
                            <th className='text-center'>สถานะ</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='text-center'>คุณอภิวัฒน์</td>
                            <td className='text-center'>หนองจ๊อม,สันทราย</td>
                            <td className='text-center'><span className="badge badge-info">รอจัดส่ง</span></td>
                            <td >
                                <div className="btn-toolbar" role="toolbar">
                                    <div className='btn-group btn-group-sm mr-2' role="group">
                                        <button type="button" className='btn btn-primary text-white'>เส้นทาง</button>
                                        <button type="button" className="btn btn-danger">ยกเลิก</button>
                                    </div>
                                    <div className="btn-group btn-group-sm mr-2" role="group" >
                                        <button type="button" className='btn btn-success text-white' onClick={()=>{_Payment()}}>ชำระเงิน</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className='text-center'>ซาวด์</td>
                            <td className='text-center'>วัดเกตุ,อำเภอเมือง</td>
                            <td className='text-center'><span className="badge badge-danger">ยกเลิก</span></td>
                            <td>
                                <div className="btn-toolbar" role="toolbar">
                                    <div className='btn-group btn-group-sm mr-2' role="group">
                                        <button type="button" className='btn btn-primary text-white'>เส้นทาง</button>
                                        <button type="button" className='btn btn-success text-white' onClick={()=>{_Payment()}}>ชำระเงิน</button>
                                    </div>
                                    <div className="btn-group btn-group-sm mr-2" role="group" >
                                        <button type="button" className="btn btn-danger" disabled={true}>ยกเลิก</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className='text-center'>ซาวด์</td>
                            <td className='text-center'>วัดเกตุ,อำเภอเมือง</td>
                            <td className='text-center'><span className="badge badge-info">รอจัดส่ง</span></td>
                            <td>
                                <div className="btn-toolbar" role="toolbar">
                                    <div className='btn-group btn-group-sm mr-2' role="group">
                                        <button type="button" className='btn btn-primary text-white'>เส้นทาง</button>
                                        <button type="button" className='btn btn-success text-white' onClick={()=>{_Payment()}}>ชำระเงิน</button>
                                    </div>
                                    <div className="btn-group btn-group-sm mr-2" role="group" >
                                        <button type="button" className="btn btn-danger">ยกเลิก</button>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
        <Payment ref={_PaymentRef} />
        </>
    )
}

export default React.memo(MapShow)

