import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState } from 'react';
import {Modal} from "reactstrap";

const ParcelInfo = forwardRef((props,ref) =>{
    const [ModalState,setModalState] = useState(false);

    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);


    useImperativeHandle(ref, () => ({
        Show(TransactionID){
            console.log(TransactionID);
            setTimeout(()=>{setModalState(true);},100)

        }
    }));
   
    return (
        <>
        <Modal isOpen={ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className='modal-header'>
                <h5 className="modal-title">ชำระเงิน</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setModalState(false)}}>
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className='modal-body'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <img src="https://dms.taila.co.th/demoQR.jpg"/>
                    </div>
                </div>
            </div>
            <div className='modal-footer'>
                <button type="button" style={{width: "100px"}} className="btn btn-danger" data-dismiss="modal"  onClick={()=>{setModalState(false)}}>ปิด</button> 
            </div>

        </Modal>
        </>)
        
});

export default React.memo(ParcelInfo)