import React from 'react'
import { Routes, Route } from 'react-router-dom'
import {PageBlank,PageError404,PageMapShow} from './Pages'

const router =() =>{
    return (
        <Routes>
            <Route path="*" element={<PageMapShow />} />
        </Routes>
    )
    
}

export default React.memo(router)