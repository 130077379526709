import React from "react";
const Content =(props) =>{
    return (
        <React.Fragment>
            <div className="container-fluid">
                {props.children}
            </div>
        </React.Fragment>
    )
}
export default React.memo(Content)