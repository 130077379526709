import React, { useState,useEffect  } from 'react'
import Content from './Content'
import Router from '../router'
import {Loader,FooterStyle1} from '../Components'
import '../assets/scss/style.scss';
import '../assets/css/style.css';
import {Helmet} from "react-helmet";
import Swal from 'sweetalert2';

class Layout extends React.Component{

    render() {

        return (
            <>
            <Helmet>
                <title>Delivery Management System</title>
            </Helmet>
            <React.Fragment>
                <Content>
                    <Loader />
                    <div className='wrapper'>
                        <Router/>
                    </div>
                </Content>
            </React.Fragment>
            </>
        )
    }
}


export default Layout;
